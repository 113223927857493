<template>
    <div>
        <div class="container-fluid">
            
            <div class="row mt-4">
                <div class='col-12'>
                    <h4 class="card-title d-inline-block d-print-none">View <span class="text-capitalize">{{ticket_state}}</span> Shipping Ticket</h4>
                    <h1 class="d-none d-print-inline-block"><span class="text-capitalize">{{ticket_state}}</span> Shipping Ticket</h1>
                    <div class="action d-print-none float-right">
                        <a class="btn btn-primary btn--icon-text ml-3 mr-3" v-tooltip="'Print Shipping Ticket'" onclick="window.print()"><i class="zwicon-printer"></i> Print Shipping Ticket</a>
                    </div>
                </div>
            </div>

            <div class="card mt-3">
                <div class="card-body">
                    <div class="row">
                        <div class="col-4">
                            <div class="pr-2">
                                <h5>Ticket Details</h5>
                                <hr/>
                                <div class="form-group mb-3">
                                    <label class="w-100">Date<span class='ml-5 float-right'>{{ticket_date}}</span></label>
                                </div>
                                <div class="form-group mb-3">
                                    <label class="w-100">Shipping Type<span class='ml-5 float-right text-capitalize'>{{ticket_type}}</span></label>
                                </div>
                                <div class="form-group mb-3">
                                    <label class="w-100">Created By <span class='ml-5 float-right text-capitalize'>{{ticket_creator}}</span></label>
                                </div>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="pr-2">
                                <h5>
                                    Receiver 
                                </h5>
                                <hr/>
                                <div class="form-group mb-3">
                                    <label class="w-100">To<span class='ml-5 float-right'>{{to_to}}</span></label>
                                </div>
                                <div class="form-group mb-3">
                                    <label class="w-100">Address<span class='ml-5 float-right'>{{to_address}}</span></label>
                                </div>
                                <div class="form-group mb-3">
                                    <label class="w-100">City / Province<span class='ml-5 float-right'>{{to_region}}</span></label>
                                </div>
                                <div class="form-group mb-3">
                                    <label class="w-100">Contact Name<span class='ml-5 float-right'>{{to_contact_name}}</span></label>
                                </div>
                                <div class="form-group mb-3">
                                    <label class="w-100">Contact #<span class='ml-5 float-right'>{{to_contact_number}}</span></label>
                                </div>
                                <div class="form-group mb-3">
                                    <label class="w-100">Oil Company<span class='ml-5 float-right'>{{to_oil_company}}</span></label>
                                </div>
                                <div class="form-group mb-3">
                                    <label class="w-100">Purchase Order<span class='ml-5 float-right'>{{to_purchase_order}}</span></label>
                                </div>
                            </div>
                        </div>
                        <div class="col-4">
                            <h5>
                                Sender 
                            </h5>
                            <hr/>
                            <div class="form-group mb-3">
                                <label class="w-100">From<span class='ml-5 float-right'>{{from_from}}</span></label>
                            </div>
                            <div class="form-group mb-3">
                                <label class="w-100">Address<span class='ml-5 float-right'>{{from_address}}</span></label>
                            </div>
                            <div class="form-group mb-3">
                                <label class="w-100">City / Province<span class='ml-5 float-right'>{{from_region}}</span></label>
                            </div>
                            <div class="form-group mb-3">
                                <label class="w-100">Contact Name<span class='ml-5 float-right'>{{from_contact_name}}</span></label>
                            </div>
                            <div class="form-group mb-3">
                                <label class="w-100">Contact #<span class='ml-5 float-right'>{{from_contact_number}}</span></label>
                            </div>
                            <div class="form-group mb-3">
                                <label class="w-100">Oil Company<span class='ml-5 float-right'>{{from_oil_company}}</span></label>
                            </div>
                            <div class="form-group mb-3">
                                <label class="w-100">Work Order<span class='ml-5 float-right'>{{from_work_order}}</span></label>
                             </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card mt-3">

                <div class="card-body">
                    <div class="row">
                        <div class="col-12"> 
                            <h5>
                                Inventory Item List
                            </h5>
                            <table class="table table-sm mb-0 mt-4" v-if='inventory_items !== undefined && inventory_items.length > 0'>
                                <thead class="thead-dark">
                                    <tr>
                                        <th>Serial No</th>
                                        <th>Description</th>
                                        <th>Dirty</th>
                                        <th>Pin Conn</th>
                                        <th>Pro</th>
                                        <th>Box Conn</th>
                                        <th>Pro</th>
                                        <th>Comments</th>
                                    </tr>
                                </thead>
                                <tbody style="line-height:2.5rem;">
                                    <tr v-for='item in inventory_items' v-bind:key="item.inventory_id">
                                        <td>{{item.serial_no}}</td>
                                        <td>{{description_name(item.description)}}</td>
                                        <td>
                                            <i v-if="item.dirty" class="zwicon-checkmark-circle" :class="{'text-success': item.dirty}"></i>
                                        </td>
                                        <td>{{connection_name(item.pinn_conn)}}</td>
                                        <td>
                                            <i v-if="item.pin_conn_pro" class="zwicon-checkmark-circle" :class="{'text-success': item.pin_conn_pro}"></i>
                                        </td>
                                        <td>{{connection_name(item.box_conn)}}</td>
                                        <td>
                                            <i v-if="item.box_conn_pro" class="zwicon-checkmark-circle" :class="{'text-success': item.box_conn_pro}"></i>
                                        </td>
                                        <td>{{item.comments}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card mt-3">

                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <h5>
                                Non-Inventory Item List
                            </h5>
                            <table class="table table-sm mb-0 mt-4" v-if='non_inventory_items !== undefined && non_inventory_items.length > 0'>
                                <thead class="thead-dark">
                                    <tr>
                                        <th>Owner</th>
                                        <th>Serial No</th>
                                        <th>Description</th>
                                        <th>Comments</th>
                                        
                                    </tr>
                                </thead>
                                <tbody style="line-height:2.5rem;">
                                    <tr v-for='item in non_inventory_items' v-bind:key="item">
                                        
                                        <td>{{company_name(item.company_id)}}</td>
                                        <td>{{item.serial_no}}</td>
                                        <td>{{item.description}}</td>
                                        <td>{{item.comments}}</td> 
                                        
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card mt-3">

                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <h5>Shipping Information</h5>
                            <hr/>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4">
                            <div class="pr-2">
                                <div class="form-group mb-3">
                                    <div class="row">
                                        <div class="col-6 text-left"><label for="call_in" class="w-100">Call In?</label></div>
                                        <div class="col-6 text-right">
                                            <i v-if="shipping_call_in" class="zwicon-checkmark-circle" :class="{'text-success': shipping_call_in}"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group mb-3">
                                    <div class="row">
                                        <div class="col-6 text-left"><label for="time" class="w-100">Time</label></div>
                                        <div class="col-6 text-right">
                                            {{shipping_time}}
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group mb-3">
                                    <div class="row">
                                        <div class="col-6 text-left"><label for="time" class="w-100">Trucking Co</label></div>
                                        <div class="col-6 text-right">
                                            {{shipping_trucking_co}}
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group mb-3">
                                    <div class="row">
                                        <div class="col-6 text-left"><label for="time" class="w-100">Unit No</label></div>
                                        <div class="col-6 text-right">
                                            {{shipping_unit_no}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-4 text-left">
                            <div class="form-group mb-3">
                                <div class="row">
                                    <div class="col-6 text-left"><label for="time" class="w-100">Driver Name</label></div>
                                    <div class="col-6 text-right">
                                        {{shipping_driver_name}}
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mb-3">
                                <div class="row">
                                    <div class="col-6 text-left"><label for="call_in" class="w-100">Driver Sign?</label></div>
                                    <div class="col-6 text-right">
                                        <div class="checkbox-char checkbox-char-small float-right">
                                            <i v-if="shipping_driver_sign" class="zwicon-checkmark-circle" :class="{'text-success': shipping_driver_sign}"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-4 text-left">
                            <div class="form-group mb-3">
                                <div class="row">
                                    <div class="col-6 text-left"><label for="time" class="w-100">Shipper Name</label></div>
                                    <div class="col-6 text-right">
                                        {{shipping_shipper_name}}
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mb-3">
                                <div class="row">
                                    <div class="col-6 text-left"><label for="time" class="w-100">Receiver Name</label></div>
                                    <div class="col-6 text-right">
                                        {{shipping_receiver_name}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            
            <hr class="mt-5">
        </div>
    </div>
</template>

<style scoped>
    label{
        font-weight: bold;
    }
    label span{
        font-weight: normal;
    }
</style>

<script>

	import { mapGetters } from "vuex";
	import store from "@/store";
	import router from "@/router";

    import { LOAD_COMPANY_LIST, LOAD_TICKET_DETAILS } from "@/store/actions.type";
    import { EDIT_TICKET_ENTRY } from '../../store/actions.type';
	

    export default {
        name: 'TicketAdd',
        components: {
        },
        props: {
            
        },
        data(){
            return {
                ticket_date: '',
                ticket_state: '',

                ticket_id: '',
                ticket_creator: '',

                edit_to: false,
                to_to: '',
                to_address: '',
                to_region: '',
                to_contact_name: '',
                to_contact_number: '',
                to_oil_company: '',
                to_purchase_order: '',

                edit_from: false,
                from_from: '',
                from_address: '',
                from_region: '',
                from_contact_name: '',
                from_contact_number: '',
                from_oil_company: '',
                from_work_order: '',

                shipping_call_in: false,
                shipping_time: '',
                shipping_driver_sign: false,
                shipping_driver_name: '',
                shipping_trucking_co: '',
                shipping_unit_no: '',
                shipping_receiver_name: '',
                shipping_shipper_name: '',


                show_add_item_modal: false,
                inventory_items: [],
                show_add_non_inventory_modal: false,
                non_inventory_items: [],

                show_add_inventory_modal: false,

                date_config: {
                    altInputClass: 'form-control'
                }
            }
        },
        beforeMount() {
            
            this.load_company_list(function(){
                
			}, function(){
                
				router.replace({ name: 'inventory', params: { error: "An error occured while attempting to load company list." }});
			});

            console.log("Loading ticket: "+this.$route.params.id)
            store.dispatch(LOAD_TICKET_DETAILS, this.$route.params.id).then(() => {
					this.load_company_list(function(){}, function(){
                        router.replace({ name: 'inventory', params: { error: "An error occured while attempting to load company list." }});
                    });

                    this.ticket_id = this.ticket_details.details.shipping_ticket_id;
                    this.ticket_creator = this.ticket_details.details.creator_name;
                    this.ticket_state = this.ticket_details.details.state;

                    this.ticket_date = this.ticket_details.details.date;
                    this.ticket_type = this.ticket_details.details.type;

                    this.to_to = this.ticket_details.receiver.to;
                    this.to_address = this.ticket_details.receiver.address;
                    this.to_region = this.ticket_details.receiver.city_prov;
                    this.to_contact_name = this.ticket_details.receiver.contact_name;
                    this.to_contact_number = this.ticket_details.receiver.contact_number;
                    this.to_oil_company = this.ticket_details.receiver.oil_company;
                    this.to_purchase_order = this.ticket_details.receiver.po_number;

                    this.from_from = this.ticket_details.sender.from;
                    this.from_address = this.ticket_details.sender.address;
                    this.from_region = this.ticket_details.sender.city_prov;
                    this.from_contact_name = this.ticket_details.sender.contact_name;
                    this.from_contact_number = this.ticket_details.sender.contact_number;
                    this.from_oil_company = this.ticket_details.sender.oil_company;
                    this.from_work_order = this.ticket_details.sender.po_number;

                    this.shipping_call_in = this.ticket_details.shipping.call_in ? true : false;
                    this.shipping_driver_name = this.ticket_details.shipping.driver_print;
                    this.shipping_driver_sign = this.ticket_details.shipping.driver_sign ? true : false;
                    this.shipping_receiver_name = this.ticket_details.shipping.receiver;
                    this.shipping_shipper_name = this.ticket_details.shipping.shipper;
                    this.shipping_time = this.ticket_details.shipping.time;
                    this.shipping_trucking_co = this.ticket_details.shipping.trucking_co;
                    this.shipping_unit_no = this.ticket_details.shipping.unit_no;
                    
                    this.inventory_items = this.ticket_details.inventory_items;
                    this.non_inventory_items = this.ticket_details.noninventory_items;


				}).catch(() => {
					router.replace({ name: 'inventory', params: { error: "An error occured while attempting to load ticket details." }});
				}).finally(() => {
					this.list_loading = false;
				});

		},
        computed: {
            ticket_data(){
				return {
                    "shipping_ticket_id": parseInt(this.ticket_id),
					"details": {
						"date": this.ticket_date,
						"type": this.ticket_type,
					},
					"sender":{
                        "entity_id": this.ticket_details.sender.entity_id,
						"from": this.from_from,
						"address": this.from_address,
						"city_prov": this.from_region,
						"contact_name": this.from_contact_name,
						"contact_number": this.from_contact_number,
                        "oil_company": this.from_oil_company,
						"po_number": this.from_work_order
					},
					"receiver":{
                        "entity_id": this.ticket_details.receiver.entity_id,
                        "to": this.to_to,
						"address": this.to_address,
						"city_prov": this.to_region,
						"contact_name": this.to_contact_name,
						"contact_number": this.to_contact_number,
                        "oil_company": this.to_oil_company,
						"po_number": this.to_purchase_order
					},
					"shipping":{
						"time": this.shipping_time,
						"trucking_co": this.shipping_trucking_co,
						"unit_no": this.shipping_unit_no,
						"driver_print": this.shipping_driver_name,
						"driver_sign": this.shipping_driver_sign,
						"call_in": this.shipping_call_in,
						"shipper": this.shipping_shipper_name,
						"receiver": this.shipping_receiver_name
					},
					"inventory_items": this.inventory_items,
					"noninventory_items": this.non_inventory_items
				}
			},
            ...mapGetters(['company_list','ticket_details', 'user', 'loading','inventory_list','size_list','category_list','connector_list','description_list'])
        },
        methods: {
            description_name(desc_id){
                if(this.description_list.list === undefined) return "";
                let item = this.description_list.list.filter(x => x.configuration_id == desc_id);
                if(item.length == 1) return item[0].name;
                else return '???';
            },
            category_name(desc_id){
                if(this.category_list.list === undefined) return "";
                let item = this.category_list.list.filter(x => x.configuration_id == desc_id);
                if(item.length == 1) return item[0].name;
                else return '???';
            },
            size_name(desc_id){
                if(this.size_list.list === undefined) return "";
                let item = this.size_list.list.filter(x => x.configuration_id == desc_id);
                if(item.length == 1) return item[0].name;
                else return '???';
            },
            connection_name(desc_id){
                if(this.connector_list.list === undefined) return "";
                let item = this.connector_list.list.filter(x => x.configuration_id == desc_id);
                if(item.length == 1) return item[0].name;
                else return '???';
            },
            company_name(desc_id){
                if(this.company_list.list === undefined) return "";
                let item = this.company_list.list.filter(x => x.company_id == desc_id);
                if(item.length == 1) return item[0].name;
                else return '???';
            },
            add_non_inv_item(){
                this.show_add_non_inventory_modal = true;
            },
            non_inventory_item_add(item){
                this.non_inventory_items.push(item);
                this.show_add_non_inventory_modal = false;
            },
            remove_inv_item(index){
                this.inventory_items.splice(index,1);
            },
            remove_noninv_item(index){
                this.non_inventory_items.splice(index,1);
            },
            add_inv_item(){
                this.show_add_inventory_modal = true;
            },
            inventory_item_add(item){
                this.show_add_inventory_modal = false;
                if(this.inventory_items.filter(x => x.inventory_id == item.inventory_id).length > 0) return
                item.dirty = !!item.dirty
                item.box_conn_protector = false;
                item.pinn_conn_protector = false;
                
                this.inventory_items.push(item);
                
            },
            load_company_list(success_fn, failure_fn = () => {}){
				this.list_loading = true;
				this.filter_error = "";
				
				store.dispatch(LOAD_COMPANY_LIST).then(data => {
					success_fn(data);
				}).catch(message => {
					failure_fn(message);
				}).finally(() => {
					this.list_loading = false;
				});
			},
            save_ticket(){
                //TODO: Validate form
                store.dispatch(EDIT_TICKET_ENTRY, this.ticket_data).then(() => {
                    router.replace({ name: 'ticket_list'});
				}).catch(message => {
                    console.log("Something went wrong saving ticket: "+message);
				});
            }
        }
    }
</script>